<template>
	<div id="empresas-servicos">
        <div class="tabela-empresas-servicos">
            <div class="col-12 novo-servico">
                <v-btn class="primary-button" raised @click="dialog_servico = true">
                    <i class="mr-2 fas fa-plus"></i> Cadastrar serviço
                </v-btn>
            </div>
            <CustomTable 
                v-if="headers != ''"
                :action="'getEmpresasServicos'"
                :getter="$store.getters.empresas"
                :headers="headers"
                :search="true"
                :title="'Empresas/Serviços'"
                :pagination="true"
                :filters="filtros"
                ref="tabela"
            >
              <template v-slot:status="{ item }">
                <span v-if="item.status">
									<img src="@/assets/images/icon-ativo.png">
								</span>
								<span v-else>
									<img src="@/assets/images/icon-inativo.png">
								</span>
              </template>
              <template v-slot:acoes="{ item }">
                <v-btn class="primary-button" raised small @click="editarServico(item.empresa_servico_id)">
									<i class="fas fa-cog"></i>
									Editar
                </v-btn>
              </template>
            </CustomTable>
            <v-dialog v-model="dialog_servico" persistent max-width="600px">
              <v-card>
              	<v-card-title>
									<span class="headline" v-if="empresa_servico.empresa_servico_id" > Editar serviço </span>
									<span class="headline" v-else > Cadastrar serviço </span>
									<v-btn class="exit-button" small @click="closeservico">
										<v-icon>fas fa-times</v-icon>
									</v-btn>
									<p>*Campos obrigatórios</p>
								</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="form_empresa_servico">
                      <div class="row">
                        <div class="col-12">                                        
													<label class="primary-text-field-label">Nome*</label>
													<v-text-field
														class="primary-text-field"
														:rules="[v => !!v || 'Campo URL amigável obrigatório']" 
														v-model="empresa_servico.url_amigavel"
														label="Nome"
														solo
														hide-details
													/>
                      	</div>
												<div class="col-12">
													<label class="primary-text-field-label">URL sistema</label>
													<v-text-field
														class="primary-text-field"
														v-model="empresa_servico.url_sistema" 
														label="URL sistema"
														hide-details
														solo
                          />
                      	</div>
                        <div v-if="empresa_servico.empresa_servico_id"  class="col-12">
													<label class="primary-text-field-label">Status*</label>
													<v-select 
														class="primary-select"
														:rules="[v => !!v || 'Campo Status obrigatório']" 
														:items="['Ativo', 'Inativo']" 
														v-model="empresa_servico.status" 
														label="Status"
														hide-details
														solo
                          />
                        </div>                                    
                        <div class=" col-12">
													<label class="primary-text-field-label">Serviço*</label>
													<v-select
														class="primary-select"
														:rules="[v => !!v || 'Campo obrigatório']" 
														:items="servicos"
														item-text="nome" 
														item-value="servico_id"
														v-model="empresa_servico.servico_id"
														label="Serviço"
														solo
														hide-details
													/>
												</div>
												<div class="col-12">
													<label class="primary-text-field-label">Merchant ID*</label>
													<v-text-field
														class="primary-text-field"
														:rules="empresa_servico.servico_id == 1 ? [v => !!v || 'Campo Merchant ID obrigatório'] : ''"
														v-model="empresa_servico.merchant_id" 
														label="Merchant ID"
														hide-details
														solo
														/>
												</div>
												<div class=" col-12">
													<label class="primary-text-field-label">Empresa*</label>
													<v-select
														class="primary-select"
														:rules="[v => !!v || 'Campo obrigatório']" 
														:items="empresas"
														item-text="nome" 
														item-value="empresa_id"
														v-model="empresa_servico.empresa_id"
														label="Empresa"
														solo
														hide-details
													/>
												</div>
												<div class="col-12">
													<label class="primary-text-field-label">Formas de Pagamento*</label>
													<v-select
														class="primary-select"
														:rules="[v => !!v || 'Campo Formas de Pagamento obrigatório']"
														:items="['Pagamento online', 'Pagamento na entrega', 'Todas']"
														v-model="empresa_servico.formas_de_pagamento"
														label="Formas de Pagamento"
														hide-details
														solo			
													/>
												</div>
                      </div>
                    </v-form>
                  </v-container>
                </v-card-text>
								<v-card-actions>
									<div class="wrapper-btns">
										<v-btn class="primary-button" medium @click="enviarservico">
											Salvar
										</v-btn>
									</div>
								</v-card-actions>
            	</v-card>
          	</v-dialog>
					<DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
					<Loader v-if="loading"/>
      	</div>
	</div>
</template>

<script>
	// importa o store
	import store from '@/store'
	// importa o componente de DialogMensagem 
	import DialogMensagem from '@/components/DialogMensagem.vue'
	// importa o componente de Loader 
	import Loader from '@/components/Loader.vue'
	// importa o componente de CustomTable 
	import CustomTable from '@/components/CustomTable.vue'

	// exporta o componente
	export default {
		// nome do componente
		name: 'empresas_servicos',
		// componentes filhos1
		components: {
			// componente de DialogMensagem
			DialogMensagem,
			// componente de CustomTable
			CustomTable,
			// componente de Loader
			Loader,
		},
		// dados do componente
		data: () => ({
			// variável para mostrar a modal para editar/criar um empresa
			dialog_servico: false,
			// variável para mostrar a modal para configuracoes de menu da empresa
			dialog_resposta: false,
			// variável para a mensagem de resposta
			resposta: {},
			// variável para o loading
			loading: false,
			// variável para criar/editar empresa
			empresa_servico: {
				empresa_servico_id: '',
				url_amigavel: '',
				url_sistema: '',
				merchant_id: '',
				status: '',
				servico_id: '',
				empresa_id: '',
				formas_de_pagamento: ''
			},
			// variável para o cabeçalho da tabela
			headers: [
				{
					value: 'empresa_servico_id',
					text: 'ID',
					sortable: true,
				},
				{
					value: 'url_amigavel',
					text: 'URL amigável',
					sortable: true,
				},
				{
					value: 'formas_de_pagamento',
					text: 'Formas de pagamento',
					sortable: true,
				},
				{
					value: 'status',
					text: 'Status',
					sortable: true,
				},
				{
					value: 'servico.nome',
					text: 'Serviço',
					sortable: true,
				},
				{
					value: 'empresa.nome',
					text: 'Empresa',
					sortable: true,
				},
				{
					value: 'acoes',
					sortable: false,
					text: 'Ações',
				},
			],
			servicos: [],
			empresas: [],
			// variável para os filtros da tabela
			filtros: {
				perPage: 10,
			},
		}),
		// funções deste componente
		methods: {
			async init(){
				await this.buscaEmpresas()
				await this.buscaServicos()
			},	
			// função para enviar um empresa
			async enviarservico(){
				// caso os campos do formulário estejam válidos
				if (this.$refs.form_empresa_servico.validate()){
					// coloca o componente como loading
					this.loading = await true
					// coloeta os dados da empresa
					let dados = await {
						empresa_servico_id: await this.empresa_servico.empresa_servico_id,
						url_amigavel: await this.empresa_servico.url_amigavel,
						url_sistema: await this.empresa_servico.url_sistema,
						merchant_id: await this.empresa_servico.merchant_id,
						status: await this.empresa_servico.status,
						servico_id: await this.empresa_servico.servico_id,
						empresa_id: await this.empresa_servico.empresa_id,
						formas_de_pagamento: await this.empresa_servico.formas_de_pagamento,
					}
					// caso exista um empresa_servico_id 
					if(this.empresa_servico.empresa_servico_id){
						// coleta o status da empresa
						dados.status = await this.empresa_servico.status == 'Ativo' ? true : false
						// coleta o id da empresa
						dados.codigo = await this.empresa_servico.empresa_servico_id
					}
					// faz a requisição para o back para coletar os grupos
					var resp = await store.dispatch('postEmpresasServicos', dados)
					// caso o status da resposta seja 200 (deu certo)
					if(resp.status != 200){
						this.$swal({
                            title: 'Atenção',
                            icon:'warning',
                            text: 'Algo deu errado!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: false
                        })
					// caso tenha dado algum erro
					}else{
						this.$swal({
                            icon:'success',
                            text: 'Empresa ' + (this.empresa_servico.empresa_servico_id ? 'editada' : 'cadastrada') + ' com sucesso!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true
                        })
						this.closeservico()
					}
					// retira o loading do componente
					this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
				}
			},
			// função para coletar um empresa para editar
			async editarServico(empresa_servico_id){
				// coloca o componente como loading
				this.loading = true
				// faz um dispatch para uma action no vuex store para pegar um empresa passando o id 
				var resp = await store.dispatch('getEmpresaServico', empresa_servico_id)

				// caso o status da resposta seja 200 (deu certo) 
				if(resp.status == 200){
					// atribui os dados da empresa vindos do back à váriável local
					this.empresa_servico.empresa_servico_id = await resp.data.dados.empresa_servico_id || ''
					this.empresa_servico.url_amigavel = await resp.data.dados.url_amigavel || ''
					this.empresa_servico.url_sistema = await resp.data.dados.url_sistema || ''
					this.empresa_servico.merchant_id = await resp.data.dados.merchant_id || ''
					this.empresa_servico.status = await resp.data.dados.status ? 'Ativo' : 'Inativo'
					this.empresa_servico.servico_id = await resp.data.dados.servico_id || ''
					this.empresa_servico.empresa_id = await resp.data.dados.empresa_id || ''
					this.empresa_servico.formas_de_pagamento = await resp.data.dados.formas_de_pagamento || ''
					this.dialog_servico = true
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					// this.resposta.mensagem = await  resp.data.message || resp.data.error[0].message
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
				// atualiza a tabela
				this.$refs.tabela.init()
			},
			// função que roda quando é fechada a modal de create/edit empresa
			closeservico(){
				// fecha a modal
				this.dialog_servico = false
				// limpa os dados locais da empresa
				this.empresa =  {
					empresa_servico_id: '',
					url_amigavel: '',
					url_sistema: '',
					merchant_id: '',
					status: '',
					servico_id: '',
                    empresa_id: '',
                    formas_de_pagamento: ''
				}
			},
			// função que roda quando é fechada a modal de create/edit empresa
			closeconfigmenu(){
				// fecha a modal
				this.dialog_config_menu = false
				// limpa os dados locais da empresa
				this.config_menu.empresa = ''
				this.config_menu.mensagem_menu_superior = ''
				this.config_menu.mensagem_menu_inferior = ''
				this.config_menu.mensagem_menu_opcao_invalida_superior = ''
				this.config_menu.mensagem_menu_opcao_invalida_inferior = ''
			},
			// função que roda quando é fechada a modal de create/edit empresa
			closeconfignome(){
				// fecha a modal
				this.dialog_config_nome = false
				// limpa os dados locais da empresa
				this.config_nome.mensagem_pergunta = ''
				this.config_nome.pergunta_nome = true
				this.config_nome.confirma_nome = true
				this.config_nome.mensagem_confirmar_nome = ''
			},
			async limparCampos(){
                this.novoFrete.fretes[0].valor_frete = ''
                this.novoFrete.fretes[0].de_km = ''
                this.novoFrete.fretes[0].ate_km = ''
                this.novoFrete.fretes[0].bairro_id = ''
            },
			async buscaEmpresas(){
				var resp = await store.dispatch('getEmpresaSelect');
				this.empresas = resp.data.dados
			},
			async buscaServicos(){
				var resp = await store.dispatch('getServicoSelect');
				this.servicos = resp.data.dados
			},
		},
		mounted(){
			// função de início do componente
			this.init()
		},
	}
</script>

<style lang="scss" scoped>
	#empresas-servicos{
		display: flex;
		flex-wrap: wrap;
		max-height: 100%;
		padding: 24px;
		font-family: 'Poppins';
		.primary-button{
			background-color: #FE8204;
			color: #fff;
			font-weight: 500;
            font-size: 14px;
			svg{
				margin-right: 5px;
			}
		}
		.tabela_{
			.status{
				img{
					width: 20px;
					margin-left: 10px;
				}
			}
		}
		.tabela-empresas-servicos{
    		border-radius: 10px;
            width: 100%;
			.novo-servico{
				display: flex;
				justify-content: flex-end;
				padding-bottom: 0;
				button{
					i, svg{
						margin-right: 10px;
					}
					text-transform: uppercase;
				}
			}
			h2{
				font-size: 25px;
				color: #404040;
				border-bottom: 4px solid #FE8204;
				width: max-content;
			}
			table{
				font-weight: 500;
			}
		}
	}
	.v-dialog{
		font-family: 'Poppins';
		.v-card{
			background-color: #f9f9f9;
			padding: 15px;
		}
		.v-card__title{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			text-transform: uppercase;
			font-family: 'Poppins' !important;
			span{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				font-weight: 600;
				color: #404040;
				font-family: 'Poppins' !important;
				img, svg{
					margin-right: 15px;
					path{
						fill: #404040;
					}
				}
			}
			button{
				color: #fff;
			}            
            .exit-button{
                color: #404040;
                font-weight: 600;
                font-size: 15px;
                box-shadow: none;
                background-color: transparent;
                svg{
                    margin-right: 0;
                }
            }
			p{
				font-size: 14px;
    			width: 100%;
				color: #969696;
				margin-bottom: 0;
			}
		}
		.primary-button{
			background-color: #FE8204 !important;
			color: #fff !important;
			font-weight: 500;
			font-size: 15px;
		}
        .wrapper-btns{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            button{
                font-size: 18px;
            }
        }
	}
</style>